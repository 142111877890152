import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";

const actions = [
  {
    icon: "mdi-file-pdf-box",
    action: "downloadPdf",
    name:"Скачать PDF"
  },
  null,
  {
    icon: "mdi-file-account-outline",
    action: "createPass",
    name:"Создать пропуск"
  },
  {
    icon:"mdi-file-move-outline",
    name:"Взять в работу",
    action:"getToWork",
  },
  {
    icon:"mdi-account",
    name:"Изменить исполнителя",
    action:"changePerformer",
    divider:true,
    component:() => import('@/components/documents/change-performer.vue')
  },
]

const methods = {
  getActionIndex(actionId){
    const action = this.actions.find(i => i?.action === actionId)
    return this.actions.indexOf(action)
  },
  createPass({id}){
    this.$store.dispatch('uvr/createPassFromUvr', id).then((res) => {
      this.$success(`Создан пропуск: ${res.data.id}`)
    }).catch(() => this.$error())
  },
  getToWork({id}){
    const personId = this.$store.getters['auth/getUser'].user.id
    this.$store.dispatch('uvr/changePerformer', {documentId:id, personId}).then(() => {
      this.$success('Документ взят в работу')
      this.$store.dispatch('journal/fetchItems')
    }).catch(() => this.$error())
  },
  changePerformer({id, actionId}){
    this.selectedId = id
    this.activeAction = this.getActionIndex(actionId)
    this.showComponent = true
  },
  downloadPdf({id}){
    this.$store.dispatch('uvr/downloadPdf', id).then((res) => {
      const name = getFilenameFromContentDisposition(res)
      downloadFile({data:res.data, type:'application/pdf', name})
    }).catch(() => this.$error())
  },
}

export {
  methods, actions
}
