<template>
  <div>
    <div
      v-if="mobile"
      id="journal-cards"
    >
      <v-card
        v-for="(item, idx) in itemsModified"
        :key="`card-${idx}`"
        height="150"
        class="d-flex mb-3 pa-1"
        :class="getItemClass(item)"
      >
        <v-row>
          <v-col>
            <JournalUser
              :item="item"
              :user-id="userId"
            />
          </v-col>
          <v-col cols="4">
            {{ item.transit_procedure }}
          </v-col>
          <v-col cols="3">
            {{ item.id }}
          </v-col>
          <v-col>
            <JournalActions
              :item="item"
              @perform="onPerform"
            />
          </v-col>
          <v-col class="py-0">
            {{ item.out_number }}
          </v-col>
          <v-col class="py-0">
            {{ item.regnums }}
          </v-col>
          <v-col cols="12">
            <journal-item-e-c-l-i-e-n-t :item="item" />
          </v-col>
        </v-row>
      </v-card>
      <v-data-footer
        :pagination="cardsPagination"
        :options="options"
        :items-per-page-options="[15, 30, 50]"
        :show-first-last-page="false"
        @update:options="onCardsOptsUpdate"
      />
    </div>
    <v-data-table
      v-else
      class="rounded-t-0 border-t-0"
      :items="itemsModified"
      :headers="headers"
      disable-sort
      :item-class="getItemClass"
      :options="options"
      :server-items-length="total"
      :footer-props="{
        'items-per-page-options': [15, 30, 50],
        'show-first-last-page': false,
      }"
      @click:row="bufferDocument"
      @dblclick:row="showDocument"
      @update:items-per-page="setPaginationSize"
      @update:page="setPaginationPage"
      @mouseover.native="bufferDocumentMouseMove"
    >
      <template #[`item.declarant`]="{ item }">
        <JournalUser
          :item="item"
          :user-id="userId"
        />
      </template>
      <template #[`item.id`]="{item}">
        <document-identity
          :id="item.id"
          :declaration-date="item.declaration_date"
        />
      </template>
      <template #[`item.out_number`]="{ item }">
        {{ item.out_number && item.out_number.length > 20 ? item.out_number.substr(0, 20) + '...' : item.out_number }} <br>
        {{ item.remark }}
      </template>
      <template #[`item.declaration_date`]="{ item }">
        <span>{{ item.declaration_date }}</span>
      </template>
      <template #[`item.transport_numbers`]="{ item }">
        <span
          v-for="(number, idx) in item.transport_numbers"
          :key="idx"
        >{{ number }}<br></span>
      </template>
      <template #[`item.regnums`]="{ item }">
        <span>{{ item.reg_num_a }}</span><br>
        <span>{{ item.reg_num_b }}</span>
      </template>
      <template #[`item.status`]="{ item }">
        <journal-item-e-c-l-i-e-n-t :item="item" />
      </template>
      <template #[`item.contragent`]="{ item }">
        <span>{{ item.principal_name }}</span><br>
        <span v-if="item.carrier_name !== item.principal_name">{{
          item.carrier_name
        }}</span>
      </template>

      <template #[`item.goods`]="{ item }">
        <v-btn
          class="elevation-0 px-0"
          min-width="32px"
          small
          plain
          @click="showGoodsList(item.id, item.user_id)"
        >
          <v-icon>mdi-format-list-bulleted</v-icon>
        </v-btn>
      </template>

      <template #[`item.actions`]="{ item }">
        <JournalActions
          :item="item"
          @perform="onPerform"
        />
      </template>
    </v-data-table>
    <component
      :is="activeComponent"
      :id="selectedId"
      :root-user-id="rootUserId"
      :show.sync="showComponent"
      module="uvr"
    />
  </div>
</template>
<script>
import {mapGetters} from "vuex";
import moment from "moment";
import {capitalizeString} from "@/helpers/text";
import JournalItemECLIENT from "@/components/journal/journal-item-eclient.vue";
import GoodsList from "@/components/uvr/journal/goods-list.vue";
import {methods, actions} from "./functions";
import JournalItemNCES from "@/components/journal/journal-item-nces.vue";
import JournalTableMixin from "@/mixins/journal-table.mixin";
import JournalItemBD44 from "@/components/journal/journal-item-bd44.vue";
import DocumentIdentity from "@/components/journal/document-identity.vue";

export default {
  components: {
    DocumentIdentity,
    JournalItemBD44,
    JournalItemNCES,
    JournalItemECLIENT,
  },
  mixins:[JournalTableMixin],
  data: () => ({
    actions,
    headers: [
      {
        text: "",
        value: "declarant",
      },
      {
        text: "Тип",
        value: "transit_procedure",
      },
      {
        text: "ID",
        value: "id",
      },
      {
        text: "Исх.номер",
        value: "out_number",
      },
      {
        text: "ТС",
        value: "transport_numbers",
      },
      {
        text: "Рег.номер",
        value: "regnums",
      },
      {
        text: "Статус",
        value: "status",
      },
      {
        text: "Принципал",
        value: "contragent",
      },
      {
        text: "Товары",
        value: "goods",
      },
      // {
      //   text: "БД44",
      //   value: "bd44",
      // },
      {
        text: "",
        value: "actions",
      },
    ],
  }),
  computed: {
    ...mapGetters({
      items: "journal/getItems",
      total: "journal/totalCount",
      pagination: "journal/pagination",
      user: "auth/getUser",
    }),
    itemsModified() {
      return this.items.map((item) => {
        const {
          bd44_ecd_requests_json,
          bd44_ecd_status,
          last_name,
          first_name,
          phone,
          email,
          id,
          customs_procedure,
          correction_kind,
          document_type,
          declaration_date: date,
          reg_num_a,
          reg_num_b,
          transport_numbers,
          subject_name,
          subject_person_name,
          counter_agent_name,
          counter_agent_person_name,
          client_email,
          client_fio,
          client_phone,
          // client_comment,
          user_id,
          client_division_id,
          eclient_request_id,
          status = "",
          out_number,
          remark,
          ecd_request_id,
          is_deferred,
          is_trash,
          transit_procedure,
          carrier_name,
          principal_name,
        } = item;

        const isRequest = !!(client_division_id && true && user_id === null);

        const type = [document_type, customs_procedure, correction_kind]
          .join(" ")
          .trim();
        const declaration_date = date
          ? moment(date, "YYYY-MM-DD").format("DD.MM.YYYY")
          : "Отсутствует";

        const initials = this.getInitials({first_name, last_name});
        const fullname = this.getFullName({first_name, last_name});
        const actions = this.getItemActions(item);
        return {
          actions, // меню с действиями
          last_name,
          first_name,
          phone,
          email,
          id,
          type,
          declaration_date,
          reg_num_a,
          reg_num_b,
          client_email,
          client_fio,
          client_phone,
          bd44_ecd_requests_json,
          bd44_ecd_status: capitalizeString(bd44_ecd_status),
          // client_comment,
          transport_numbers: this.splitTransportNumber(transport_numbers),
          initials,
          user_id,
          client_division_id,
          eclient_request_id,
          fullname,
          carrier_name,
          principal_name,
          out_number,
          remark,
          ecd_request_id,
          is_deferred,
          transit_procedure,
          status: capitalizeString(status),
          subject_name: isRequest ? "" : subject_name || subject_person_name,
          counter_agent_name: isRequest
            ? ""
            : counter_agent_name || counter_agent_person_name,
          is_trash,
        };
      });
    },
  },

  methods: {
    ...methods, // методы для каждого элемента
    getDocumentTypeActions(status) {
      const actions = [...this.actions];
      if (status.myRequestDocument) {
        const available = ["downloadPdf"];
        return actions.filter((i) => {
          return available.includes(i?.action) || i === null;
        });
      }else if(status.isRequest){
        return []
      } else if (!status.hasUser) {
        const config = {
          getToWork:["doc.div.user.update"]
        }
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (status.documentFromRequest) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          createPass: ["doc.div.pdf.create"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (status.myDocument) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          createPass: ["doc.div.copy.create"],
          changePerformer: ["doc.priv.user.update"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else if (!status.myDocument) {
        const config = {
          downloadPdf: ["doc.div.pdf.create"],
          createPass: ["doc.div.copy.create"],
          changePerformer: ["doc.div.user.update"],
        };
        return this.modifyActions(this.getAvailableActions(config), status);
      } else {
        return [];
      }
    },
    modifyActions(actions, status) {
      if (!status) return actions;
      return actions.map((i) => {
        if (i?.action === "deleteDocument" && status.isTrash) {
          return {
            ...i,
            icon: "mdi-delete-off",
            name: "Восстановить",
          };
        } else if (i?.action === "changeDeferredStatus" && status.isDeffered) {
          return {
            ...i,
            icon: "mdi-play",
            name: "Возобновить",
          };
        }
        return i;
      });
    },
    showGoodsList(id) {
      this.selectedId = id;
      this.activeAction = null; // Что-бы срабатывал watch после открытия этого компонента
      this.$nextTick(() => {
        // Вызываем функцию тк после триггера watch вызывается рендер динамического компонента со значением null - который не отображается
        // Можно убрать вотчер но тогда надо добавлять колбэк к каждой функции. Либо перенести компонент в actions
        this.activeComponent = GoodsList;
        this.showComponent = true;
      });
    },
    showDocument(event, {item}) {
      if (
        this.mobile || // Просмотр только на десктопе
          (this.userId !== null && item.user_id !== this.userId) || // Доступ только к собственным декларациям
          (item.client_division_id && item.user_id === null) // Если заявка
      )
        return false;
      const route = this.$router.resolve({
        path: `/uvr/${item.id}`,
      });
      window.open(route.href, "_blank");
    },
  },
};
</script>

<style scoped>
.isApplication {
  background-color: blue !important;
}

.v-data-footer__select {
  display: block;
}
</style>
